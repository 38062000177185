.dashboard-section-card-row {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-section-card-container {
  width: 33%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

.dashboard-section-card {
  width: 100%;
  background-color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid @colors[grey-border];
}

.dashboard-section-card__title {
  width: 100%;
  color: @colors[purple];
  margin-top: 0;
  margin-bottom: 20px;
  .truncate();
}

.dashboard-section-card__icon {
  width: 140px;
  height: 140px;
  margin-bottom: 20px;
  background-size: 70px;
  background-repeat: no-repeat;
  background-position: center;
}

.dashboard-section-card__action {
  width: 100%;
  min-height: 24px;
  background-size: 24px;
  padding-left: 30px;
  margin-bottom: 5px;
  background-position: left top;
  background-image: url("/images/icons/tag-cross-red.svg");
  background-repeat: no-repeat;
  text-align: left;
  color: @colors[grey];
}

.dashboard-section-card__action--done {
  background-image: url("/images/icons/tag-tick-green.svg");
}

.dashboard-section-card__button-container {
  width: 100%;
  margin-top: auto;
  padding-top: 10px;
}

@media only screen and (max-width: 1200px) {
  .dashboard-section-card-row {
    display: block;
  }

  .dashboard-section-card-container {
    width: 100%;
    max-width: 500px;
    margin: 10px auto;
  }
}
