@flex-sizes: {
  1: 1;
  2: 2;
  3: 3;
};

@align-items: {
  start: flex-start;
  end: flex-end;
  center: center;
  stretch: stretch;
  baseline: baseline;
};

@justify-content: {
  start: flex-start;
  end: flex-end;
  center: center;
  between: space-between;
  around: space-around;
  evenly: space-evenly;
};

.flex {
  display: flex;
}

.flex--column {
  flex-direction: colunn;
}

.flex--center {
  align-items: center;
  justify-content: center;
}

each(@flex-sizes, .(@value, @name) {
  .flex__@{value} { flex: @value; }
});

each(@align-items, .(@value, @name) {
  .flex--align-@{name} { align-items: @value; }
});

each(@justify-content, .(@value, @name) {
  .flex--justify-@{name} { justify-content: @value; }
});
