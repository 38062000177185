.header {
  background-color: @colors[purple];
  padding: 20px;
  background-image: url("/images/koru-white-dim.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.header__logo {
  max-height: 80px;
}
