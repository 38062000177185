.icon-selector {
  width: 200px;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  cursor: pointer;

  &:hover {
    .icon-selector__icon {
      border-width: 2px;
    }

    .icon-selector__icon--active {
      border-width: 4px;
    }
  }
}

.icon-selector__icon {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 0px solid @colors[primary];
  background-color: white;
  margin: 0 auto;
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
  transition: border-width 0.1s;
}

.icon-selector__icon--active {
  border-width: 4px;
}

.icon-selector__label {
  margin-top: 10px;
}
