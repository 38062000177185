.form-section-navigation {
  width: 250px;
  background-color: @colors[grey];
  overflow: hidden;
  border-radius: 5px;
  color: white;
}

.form-section-navigation__item {
  position: relative;
  width: 100%;
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  // cursor: pointer;
  transition: background-color @transition-duration;
  .truncate();

  // &:after {
  //   content: '';
  //   .absolute-fill();
  //   transition: background-color @transition-duration;
  // }

  // &:hover:after {
  //   background-color: rgba(white, 0.05);
  // }
}

.form-section-navigation__item--complete {
  background-color: @colors[dark-grey];
}

.form-section-navigation__item--incomplete {
  background-color: @colors[red];
}

.form-section-navigation__item--active {
  background-color: @colors[primary];
}
