.dashboard-help-box {
  padding: 40px 20px;
  text-align: center;
  background-color: @colors[darker-background];
  color: @colors[grey];
  cursor: pointer;
  transition: color @transition-duration;
}

.dashboard-help-box--sent {
  color: @colors[primary];
}

.dashboard-help-box__image {
  width: 80px;
}

.dashboard-help-box__label {
  text-decoration: underline;
}
