each(@colors, .(@color, @name) {
  .color-@{name} {
    color: @color;
  }

  .bg-@{name} {
    background-color: @color;
  }

  .bd-@{name} {
    border-color: @color;
  }
});
