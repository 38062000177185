.date-range-input {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.date-range-input__date-input {
  flex: 1;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.date-range-input__divider {
  margin: 10px;
}
