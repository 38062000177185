.dashboard-location-box {
  padding: 20px;
  padding-left: 70px;
  background-color: @colors[grey];
  color: white;
  background-image: url("/images/icons/location-marker-white.svg");
  background-position: 20px center;
  background-size: 40px;
  background-repeat: no-repeat;
}
