.dashboard-section-results__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-section-results__row__name {
  color: @colors[purple];
  width: 140px;
  margin: 0;
  .truncate();
}

.dashboard-section-results__row__progress-bar {
  width: calc(100% - 190px);
  height: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}

.dashboard-section-results__row__progress-bar__progress {
  height: 10px;
  border-radius: 5px;
  animation: progress-bar-line-animation 1s ease-out forwards;
  transition:
    width @transition-duration,
    background-color @transition-duration;
}

.dashboard-section-results__row__percentage {
  width: 50px;
  font-weight: 600;
  text-align: right;
  transition: color @transition-duration;
  .truncate();
}

@keyframes progress-bar-line-animation {
  from {
    width: 0;
  }
}
