.video-frame {
  width: 100%;
  height: 540px;

  @media (max-width: 900px) {
    height: 500px;
  }

  @media (max-width: 800px) {
    height: 400px;
  }

  @media (max-width: 700px) {
    height: 350px;
  }

  @media (max-width: 600px) {
    height: 300px;
  }

  @media (max-width: 500px) {
    height: 250px;
  }

  @media (max-width: 400px) {
    height: 200px;
  }
}
