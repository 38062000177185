.dashboard-side-panel {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.dashboard-side-panel__gap-fill {
  border-right: 1px solid @colors[grey-border];
  flex: 1;
}
