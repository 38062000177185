.form__field {
  margin-top: 5px;
  margin-bottom: 20px;
}

.form__field--ignored {
  opacity: 0.2;
}

.form__field__label {
  display: flex;
  justify-content: space-between;
}

.form__field__label__status {
  color: @colors[green];
  margin-left: 20px;
  min-height: 20px;
  width: 60px;
  text-align: right;
}
