.section-navigation-button {
  width: 40px;
  height: 40px;
  background-image: url("/images/icons/arrow-circle-black.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.section-navigation-button--left {
  transform: rotate(90deg);
}

.section-navigation-button--right {
  transform: rotate(-90deg);
}
