.percentage-circle {
  display: block;
}

.percentage-circle__background-circle {
  fill: none;
  stroke: rgba(0, 0, 0, 0.1);
  stroke-width: 1.5;
}

.percentage-circle__line {
  fill: none;
  stroke: @colors[primary];
  stroke-width: 1.5;
  stroke-linecap: round;
  transition: stroke-dasharray 1s;
}
