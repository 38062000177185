html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
  background-color: #f6f5fa;
  color: #3d3942;
  font-family: 'Fira Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -mox-osx--font-smoothing: grayscale;
  font-size: 16px;
}
h1 {
  font-size: 36px;
  color: #2E1A47;
  line-height: 45px;
  font-weight: 400;
}
h2 {
  font-size: 27px;
  color: #2E1A47;
  line-height: 36px;
  font-weight: 500;
}
h3 {
  font-size: 22.5px;
  color: #2E1A47;
  letter-spacing: 0;
  line-height: 29.7px;
  font-weight: 500;
}
h4 {
  font-size: 18px;
  color: #303133;
  letter-spacing: 0;
  line-height: 27px;
  font-weight: 700;
}
h5 {
  font-size: 15.3px;
  color: #2E1A47;
  letter-spacing: 0.5px;
  line-height: 19.8px;
  text-transform: uppercase;
  font-weight: 400;
}
.hero-heading {
  font-size: 48px;
  color: #2E1A47;
  letter-spacing: 1px;
  line-height: 61px;
  font-weight: 700;
}
div {
  box-sizing: border-box;
}
input,
textarea,
.input {
  box-sizing: border-box;
  width: 100%;
  padding: 6px 14px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
  font-family: 'Fira Sans', sans-serif;
  text-align: left;
  transition: border-color 0.2s;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input::placeholder,
textarea::placeholder,
.input::placeholder {
  color: #999999;
}
input:focus,
textarea:focus,
.input:focus {
  border-color: #6C8849;
}
.input-dim input,
.input-dim textarea,
.input-dim .input {
  background-color: #f6f5fa;
  border-color: #f6f5fa;
}
.input-dim input:focus,
.input-dim textarea:focus,
.input-dim .input:focus {
  border-color: #6C8849;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
textarea {
  min-width: 100%;
  max-width: 100%;
}
select {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 6px 14px;
  padding-right: 40px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
  font-family: 'Fira Sans', sans-serif;
  transition: border-color 0.2s;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select:invalid {
  color: #999999;
}
select option[value=""] {
  color: #999999;
}
select:focus {
  border-color: #6C8849;
}
.input-dim select {
  background-color: #f6f5fa;
  border-color: #f6f5fa;
}
.input-dim select:focus {
  border-color: #6C8849;
}
p {
  font-size: 16px;
  color: #303133;
  line-height: 24px;
  font-weight: 400;
}
.file-input {
  box-sizing: border-box;
  padding: 20px 10px;
  border-radius: 5px;
  border: 1px solid white;
  text-align: center;
  transition: border-color 0.2s, padding 0.2s;
  background-color: #f6f5fa;
  border-color: #f6f5fa;
}
.file-input--drag {
  border-color: #999999;
  padding: 30px 10px;
}
.file-input__link {
  color: #007eb0;
  text-decoration: underline;
  cursor: pointer;
}
.file-input__status {
  color: #999999;
  margin: 10px 0;
}
.file-input__status--failed {
  color: #a94242;
}
.file-input__value {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.file-input__value__remove-button {
  color: #a94242;
  text-align: right;
  cursor: pointer;
}
.file-input__value__remove-button:hover {
  text-decoration: underline;
}
.screen {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
}
.screen__content {
  position: relative;
}
.button {
  position: relative;
  display: inline-block;
  padding: 14px 20px;
  border-radius: 4px;
  text-align: center;
  background-color: #6C8849;
  color: white;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.2s;
}
.button:hover {
  background-color: #8bae5f;
}
.button:focus {
  background-color: #6C8849;
}
.button:active {
  text-decoration: underline;
}
.button--compact {
  padding: 5px 8px;
}
.button--outline {
  background-color: transparent;
  border: 1px solid #6C8849;
  color: #6C8849;
}
.button--outline:disabled {
  border-color: #999999;
  background-color: transparent;
}
.button--outline:hover {
  background-color: #e7eae4;
}
.button--outline:hover:disabled {
  background-color: transparent;
}
.button--outline:focus {
  background-color: #d6dccf;
}
.button--outline:focus:disabled {
  background-color: transparent;
}
.button--outline:active {
  background-color: white;
}
.button--outline:active:disabled {
  background-color: transparent;
}
.button--grey {
  background-color: #999999;
}
.button--wide {
  min-width: 200px;
}
.button--full-width {
  width: 100%;
}
.button--inverse-primary {
  background-color: white;
  color: #6C8849;
}
.header {
  background-color: #2e1a47;
  padding: 20px;
  background-image: url("/images/koru-white-dim.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.header__logo {
  max-height: 80px;
}
.footer {
  position: relative;
  background-image: url("/images/footer-background.svg");
  background-position: top;
  background-size: cover;
  color: white;
  margin-top: auto;
  z-index: 10;
}
.footer__content {
  padding: 30px 24px;
  padding-top: 50px;
}
.footer__block {
  margin-bottom: 10px;
}
.footer__title {
  font-size: 18px;
  color: #B6B2CC;
  margin-top: 0;
  margin-bottom: 14px;
  font-weight: 600;
}
.footer__link {
  display: block;
  margin: 10px 0;
  color: white;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  transition: opacity 0.2s;
}
.footer__link:hover {
  opacity: 0.6;
}
.footer__text {
  color: white;
  font-size: 18px;
  font-weight: 400;
}
.footer__link--icon {
  padding-left: 30px;
  background-size: 24px;
  background-position: left center;
  background-repeat: no-repeat;
}
.footer__link--facebook-icon {
  background-image: url("/images/social-icons/facebook-white.svg");
}
.footer__link--twitter-icon {
  background-image: url("/images/social-icons/twitter-white.svg");
}
.footer__link--linkedin-icon {
  background-image: url("/images/social-icons/linkedin-white.svg");
}
.footer__partner-logo {
  width: 120px;
}
.footer__feedback-row {
  padding: 24px;
  text-align: center;
  background-color: #6C8849;
}
.footer__feedback-row a {
  color: white;
  transition: opacity 0.2s;
}
.footer__feedback-row a:hover {
  opacity: 0.5;
}
.footer__feedback-row__text {
  margin: 0;
  font-weight: 400;
}
@media only screen and (max-width: 960px) {
  .footer__text {
    font-size: 16px;
  }
  .footer__link {
    font-size: 16px;
  }
}
.cover {
  width: 100%;
  color: white;
  background-image: url('/images/fishing.jpg');
  background-position: center, center;
  background-size: cover;
}
.section {
  width: 100%;
  padding: 50px 30px;
}
.section--padding-small {
  padding-top: 20px;
  padding-bottom: 20px;
}
.section__content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.section__content--extra-small {
  max-width: 400px;
}
.section__content--small {
  max-width: 600px;
}
.section__content--medium {
  max-width: 800px;
}
.section__content--large {
  max-width: 960px;
}
.section__content--extra-large {
  max-width: 1200px;
}
.column-container {
  display: flex;
}
.column-container--align-center {
  align-items: center;
}
.column-container--align-end {
  align-items: flex-end;
}
.column-container--wrap {
  flex-wrap: wrap;
}
.column-container--justify-space-between {
  justify-content: space-between;
}
@media only screen and (max-width: 400px) {
  .column-container--extra-small-cut-off {
    display: block;
  }
  .column-container--extra-small-cut-off > .column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .column-container--small-cut-off {
    display: block;
  }
  .column-container--small-cut-off > .column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 800px) {
  .column-container--medium-cut-off {
    display: block;
  }
  .column-container--medium-cut-off > .column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 960px) {
  .column-container--large-cut-off {
    display: block;
  }
  .column-container--large-cut-off > .column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .column-container--extra-large-cut-off {
    display: block;
  }
  .column-container--extra-large-cut-off > .column {
    padding-left: 0;
    padding-right: 0;
  }
}
.form__field {
  margin-top: 5px;
  margin-bottom: 20px;
}
.form__field--ignored {
  opacity: 0.2;
}
.form__field__label {
  display: flex;
  justify-content: space-between;
}
.form__field__label__status {
  color: #618138;
  margin-left: 20px;
  min-height: 20px;
  width: 60px;
  text-align: right;
}
.check-box {
  position: relative;
  display: flex;
  margin: 5px 0;
  cursor: pointer;
  align-items: top;
}
.check-box--no-button-transition {
  cursor: default;
}
.check-box__tick-box {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 2px;
  border: 2px solid #999999;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.2s;
}
.check-box__tick-box:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 5px;
  top: 3px;
  left: 2px;
  border-left: 2px solid #999999;
  border-bottom: 2px solid #999999;
  transform: rotate(-40deg) scale(0) translate(2px);
  transition: border-color 0.2s, transform 0.2s;
}
.check-box__tick-box--active {
  border-color: #6C8849;
}
.check-box__tick-box--active:after {
  border-color: #6C8849;
  transform: rotate(-50deg) scale(1);
}
.check-box__text {
  flex: 1;
  color: #3d3942;
  font-size: 14px;
}
@media only screen and (max-width: 800px) {
  .check-box:not(.check-box--no-button-transition) {
    display: block;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__text {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 14px 20px;
    border-radius: 4px;
    text-align: center;
    background-color: #6C8849;
    color: white;
    cursor: pointer;
    overflow: hidden;
    background-color: #999999;
    transition: background-color 0.2s;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__text:hover {
    background-color: #8bae5f;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__text:focus {
    background-color: #6C8849;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__text:active {
    text-decoration: underline;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__text--active {
    background-color: #6C8849;
  }
  .check-box:not(.check-box--no-button-transition) .check-box__tick-box {
    position: absolute;
    top: -5px;
    right: -5px;
    margin: 0;
    background-color: white;
    z-index: 1;
  }
}
.radio {
  display: flex;
  margin: 5px 0;
  cursor: pointer;
  align-items: center;
}
.radio__dot {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 2px;
  border-radius: 100%;
  border: 2px solid #999999;
  box-sizing: content-box;
  transition: border-color 0.2s;
}
.radio__dot:after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  top: 1px;
  left: 1px;
  background-color: #999999;
  border-radius: 100%;
  transform: scale(0);
  transition: background-color 0.2s, transform 0.2s;
}
.radio__dot--active {
  border-color: #6C8849;
}
.radio__dot--active:after {
  background-color: #6C8849;
  transform: scale(1);
}
.radio__text {
  flex: 1;
  color: #3d3942;
  font-size: 14px;
}
@media only screen and (max-width: 800px) {
  .radio {
    display: block;
  }
  .radio__text {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 14px 20px;
    border-radius: 4px;
    text-align: center;
    background-color: #6C8849;
    color: white;
    cursor: pointer;
    overflow: hidden;
    background-color: #999999;
    transition: background-color 0.2s;
  }
  .radio__text:hover {
    background-color: #8bae5f;
  }
  .radio__text:focus {
    background-color: #6C8849;
  }
  .radio__text:active {
    text-decoration: underline;
  }
  .radio__text--active {
    background-color: #6C8849;
  }
  .radio__dot {
    display: none;
  }
}
.error-message {
  color: #a94242;
  font-size: 16px;
  margin: 5px 0;
}
.box {
  background-color: white;
  border-radius: 5px;
}
.progress-bar {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
}
.progress-bar__progress {
  height: 2px;
  background-color: #6C8849;
  transition: width 0.5s;
}
.input-wrapper {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.input-wrapper__input {
  flex: 1;
  height: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
}
.input-wrapper__input:focus + .input-wrapper__icon {
  background-color: #6C8849;
}
.input-wrapper__icon {
  width: 40px;
  height: 40px;
  background-color: #999999;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: background-color 0.2s;
}
.input-wrapper__icon--arrow-icon {
  background-image: url('/images/icons/down-white.svg');
  background-position: center;
  background-size: 30%;
  background-repeat: no-repeat;
}
.input-wrapper__icon--calendar-icon {
  background-image: url('/images/icons/calendar-white.svg');
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}
.input-wrapper__icon--click-through {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}
.input-wrapper__icon--split {
  display: flex;
  flex-direction: column;
}
.input-wrapper__icon__split {
  flex: 1;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.2s;
}
.input-wrapper__icon__split:hover {
  background-color: #6C8849;
}
.input-wrapper__icon__split + .input-wrapper__icon__split {
  border-top: 1px solid white;
}
.input-dim .input-wrapper__icon__split + .input-wrapper__icon__split {
  border-top-color: rgba(0, 0, 0, 0.2);
}
.input-wrapper__icon__split--up-arrow {
  background-image: url('/images/icons/down-white.svg');
  background-position: center;
  background-size: 30%;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}
.input-wrapper__icon__split--down-arrow {
  background-image: url('/images/icons/down-white.svg');
  background-position: center;
  background-size: 30%;
  background-repeat: no-repeat;
}
.icon-selector {
  width: 200px;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  cursor: pointer;
}
.icon-selector:hover .icon-selector__icon {
  border-width: 2px;
}
.icon-selector:hover .icon-selector__icon--active {
  border-width: 4px;
}
.icon-selector__icon {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 0px solid #6C8849;
  background-color: white;
  margin: 0 auto;
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
  transition: border-width 0.1s;
}
.icon-selector__icon--active {
  border-width: 4px;
}
.icon-selector__label {
  margin-top: 10px;
}
.section-navigation-button {
  width: 40px;
  height: 40px;
  background-image: url("/images/icons/arrow-circle-black.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.section-navigation-button--left {
  transform: rotate(90deg);
}
.section-navigation-button--right {
  transform: rotate(-90deg);
}
.overlay-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 30;
}
.overlay-content-container {
  flex: 1 1 auto;
  max-height: 100%;
  max-width: 1000px;
  overflow: auto;
}
.link {
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s;
}
.link--primary {
  color: #6C8849;
}
.link--primary:hover {
  color: #8bae5f;
}
.mobile-nav-panel {
  display: none;
  position: absolute;
  left: 0;
  top: 130px;
  width: 360px;
  max-width: 100%;
  background-color: #2e1a47;
  font-size: 16px;
  z-index: 5;
}
.mobile-nav-panel__row {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 26px;
  border-bottom: 1px solid white;
  transition: color 0.2s;
}
.mobile-nav-panel__row:hover {
  color: white;
}
.mobile-nav-panel__content {
  display: flex;
  flex-direction: column;
  padding: 26px;
}
.mobile-nav-panel__expansion {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-top: 10px;
}
.mobile-nav-panel__social-icon {
  width: 24px;
  padding-right: 6px;
  vertical-align: middle;
}
.mobile-nav-panel__link {
  box-sizing: border-box;
  width: 100%;
  margin: 5px 0;
  color: white;
  text-decoration: none;
  font-weight: 300;
}
.mobile-nav-panel__link--expansion {
  padding: 6px 0;
}
.mobile-nav-panel__link__text {
  display: inline-block;
  vertical-align: middle;
}
.mobile-nav-panel__expansion-button {
  width: 12px;
  margin-left: 10px;
}
@media only screen and (max-width: 960px) {
  .mobile-nav-panel {
    display: block;
    top: 116px;
  }
}
@media only screen and (max-width: 640px) {
  .mobile-nav-panel {
    display: block;
    top: 51px;
    width: 100%;
  }
}
.percentage-circle {
  display: block;
}
.percentage-circle__background-circle {
  fill: none;
  stroke: rgba(0, 0, 0, 0.1);
  stroke-width: 1.5;
}
.percentage-circle__line {
  fill: none;
  stroke: #6C8849;
  stroke-width: 1.5;
  stroke-linecap: round;
  transition: stroke-dasharray 1s;
}
.alert-banner {
  padding: 20px;
  padding-left: 80px;
  background-color: #999999;
  color: white;
  background-image: url("/images/icons/info-circle-white.svg");
  background-size: 40px;
  background-position: 20px center;
  background-repeat: no-repeat;
  font-size: 16px;
  border-radius: 5px;
}
.vertical-line {
  width: 1px;
  background-color: #d8d8d8;
}
.horizontal-line {
  height: 1px;
  background-color: #d8d8d8;
  margin: 0 auto;
}
.horizontal-line--width-extra-small {
  max-width: 300px;
}
.horizontal-line--thick {
  height: 4px;
}
.horizontal-line--primary {
  background-color: #6C8849;
}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.spinner {
  width: 24px;
  height: 24px;
  border: 2px solid #2e1a47;
  border-top-color: transparent;
  border-radius: 12px;
  animation-name: spinner;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.flex {
  display: flex;
}
.flex--column {
  flex-direction: colunn;
}
.flex--center {
  align-items: center;
  justify-content: center;
}
.flex__1 {
  flex: 1;
}
.flex__2 {
  flex: 2;
}
.flex__3 {
  flex: 3;
}
.flex--align-start {
  align-items: flex-start;
}
.flex--align-end {
  align-items: flex-end;
}
.flex--align-center {
  align-items: center;
}
.flex--align-stretch {
  align-items: stretch;
}
.flex--align-baseline {
  align-items: baseline;
}
.flex--justify-start {
  justify-content: flex-start;
}
.flex--justify-end {
  justify-content: flex-end;
}
.flex--justify-center {
  justify-content: center;
}
.flex--justify-between {
  justify-content: space-between;
}
.flex--justify-around {
  justify-content: space-around;
}
.flex--justify-evenly {
  justify-content: space-evenly;
}
.date-input {
  position: relative;
  width: 100%;
  padding: 8px 14px;
  padding-right: 54px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid white;
  outline: none;
  font-family: 'Fira Sans', sans-serif;
  text-align: left;
  transition: border-color 0.2s;
  background-color: white;
  cursor: pointer;
}
.date-input:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #999999;
  background-image: url("/images/icons/calendar-white.svg");
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
}
.input-dim .date-input {
  background-color: #f6f5fa;
  border-color: #f6f5fa;
}
.date-input__display-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.date-input__display-value--placeholder {
  color: #999999;
}
.date-input__picker-dropdown {
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  min-width: 100%;
  padding: 14px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  z-index: 11;
}
.date-input__picker-dropdown:after {
  content: "";
  position: absolute;
  top: 0;
  right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 5px 0;
  background-color: white;
  transform: translate(-50%, -50%) rotate(45deg);
}
.date-input__picker-dropdown__title {
  color: #999999;
  font-size: 18px;
  text-align: center;
  margin-top: 5px;
}
.date-input__picker-dropdown__pickers {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}
.date-input__picker-dropdown__picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}
.date-input__picker-dropdown__picker__arrow {
  position: relative;
  width: 40px;
  height: 40px;
}
.date-input__picker-dropdown__picker__arrow:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  transform: translate(-50%, -50%) rotate(45deg);
  border-top: 2px solid #6C8849;
  border-left: 2px solid #6C8849;
}
.date-input__picker-dropdown__picker__arrow--down {
  transform: rotate(180deg);
}
.date-input__picker-dropdown__picker__value {
  min-width: 40px;
  padding: 5px;
  background-color: #f6f5fa;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #6C8849;
}
.date-input__picker-dropdown__picker__value:focus {
  outline: none;
}
.date-input__picker-dropdown__button-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.date-range-input {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .date-range-input {
    flex-direction: column;
  }
}
.date-range-input__date-input {
  flex: 1;
}
@media only screen and (max-width: 600px) {
  .date-range-input__date-input {
    width: 100%;
  }
}
.date-range-input__divider {
  margin: 10px;
}
.previous-values {
  display: flex;
  margin-bottom: 20px;
}
.previous-values__title {
  color: #999999;
  flex-shrink: 0;
}
.previous-values__value {
  color: #999999;
  margin: 0 10px;
  white-space: pre-wrap;
}
.previous-values__copy {
  position: relative;
  display: flex;
  color: #618138;
  cursor: pointer;
}
.previous-values__copy__icon {
  margin-left: 5px;
  margin-top: 2px;
}
.previous-values__tooltip {
  position: absolute;
  top: 150%;
  left: 50%;
  transform-origin: 0 center;
  transform: scale(0) translateX(-50%);
  background-color: #3d3942;
  padding: 10px;
  color: white;
  border-radius: 3px;
  width: fit-content;
  min-width: 200px;
  text-align: center;
  z-index: 11;
  transition: transform 0.2s;
}
.previous-values__tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 14px;
  height: 14px;
  background-color: #3d3942;
  border-radius: 3px;
  transform: translate(-50%, calc(-50% + 2px)) rotate(45deg);
}
.previous-values__tooltip--open {
  transform: scale(1) translateX(-50%);
}
.previous-values__tooltip__value {
  margin-bottom: 10px;
}
.previous-values__tooltip__message {
  font-size: 12px;
  font-weight: bold;
  color: #999999;
}
.input-table {
  border: 1px solid #d8d8d8;
  border-spacing: 0;
  width: 100%;
}
.input-table__cell {
  border: 1px solid #d8d8d8;
}
.input-table__cell--header {
  padding: 5px;
}
.input-table__cell--input {
  white-space: pre-wrap;
  background-color: #f6f5fa;
  color: #999999;
}
.input-table__cell--input:focus {
  color: #007eb0;
  outline: none;
  border: 1px solid #007eb0;
}
.input-table__cell--control {
  text-align: center;
  width: 80px;
}
.input-table__cell__input {
  border: 1px solid transparent;
  padding: 8px;
  transition: color 0.2s, border-color 0.2s;
  color: #999999;
  margin: 0;
  border-radius: 0;
  outline: none;
  font-size: 16px;
}
.input-table__cell__input:focus {
  border-color: #007eb0;
  color: #007eb0;
  outline: none;
}
.color-primary {
  color: #6C8849;
}
.bg-primary {
  background-color: #6C8849;
}
.bd-primary {
  border-color: #6C8849;
}
.color-dark-primary {
  color: #59713a;
}
.bg-dark-primary {
  background-color: #59713a;
}
.bd-dark-primary {
  border-color: #59713a;
}
.color-light-primary {
  color: #8bae5f;
}
.bg-light-primary {
  background-color: #8bae5f;
}
.bd-light-primary {
  border-color: #8bae5f;
}
.color-background {
  color: #f6f5fa;
}
.bg-background {
  background-color: #f6f5fa;
}
.bd-background {
  border-color: #f6f5fa;
}
.color-darker-background {
  color: #edecf2;
}
.bg-darker-background {
  background-color: #edecf2;
}
.bd-darker-background {
  border-color: #edecf2;
}
.color-text {
  color: #3d3942;
}
.bg-text {
  background-color: #3d3942;
}
.bd-text {
  border-color: #3d3942;
}
.color-black {
  color: #3d3942;
}
.bg-black {
  background-color: #3d3942;
}
.bd-black {
  border-color: #3d3942;
}
.color-white {
  color: white;
}
.bg-white {
  background-color: white;
}
.bd-white {
  border-color: white;
}
.color-grey {
  color: #999999;
}
.bg-grey {
  background-color: #999999;
}
.bd-grey {
  border-color: #999999;
}
.color-darker-grey {
  color: #5F5D5D;
}
.bg-darker-grey {
  background-color: #5F5D5D;
}
.bd-darker-grey {
  border-color: #5F5D5D;
}
.color-dark-grey {
  color: #303133;
}
.bg-dark-grey {
  background-color: #303133;
}
.bd-dark-grey {
  border-color: #303133;
}
.color-light-grey {
  color: #d8d8d8;
}
.bg-light-grey {
  background-color: #d8d8d8;
}
.bd-light-grey {
  border-color: #d8d8d8;
}
.color-grey-border {
  color: #ECEBF0;
}
.bg-grey-border {
  background-color: #ECEBF0;
}
.bd-grey-border {
  border-color: #ECEBF0;
}
.color-red {
  color: #a94242;
}
.bg-red {
  background-color: #a94242;
}
.bd-red {
  border-color: #a94242;
}
.color-purple {
  color: #2e1a47;
}
.bg-purple {
  background-color: #2e1a47;
}
.bd-purple {
  border-color: #2e1a47;
}
.color-blue {
  color: #007eb0;
}
.bg-blue {
  background-color: #007eb0;
}
.bd-blue {
  border-color: #007eb0;
}
.color-light-purple {
  color: #B6B2CC;
}
.bg-light-purple {
  background-color: #B6B2CC;
}
.bd-light-purple {
  border-color: #B6B2CC;
}
.color-golden {
  color: #F7981C;
}
.bg-golden {
  background-color: #F7981C;
}
.bd-golden {
  border-color: #F7981C;
}
.color-green {
  color: #618138;
}
.bg-green {
  background-color: #618138;
}
.bd-green {
  border-color: #618138;
}
.margin-auto {
  margin: auto;
}
.margin-none {
  margin: 0;
}
.margin-extra-small {
  margin: 5px;
}
.margin-small {
  margin: 10px;
}
.margin-medium {
  margin: 25px;
}
.margin-large {
  margin: 50px;
}
.margin-extra-large {
  margin: 100px;
}
.margin-left-auto {
  margin-left: auto;
}
.margin-left-none {
  margin-left: 0;
}
.margin-left-extra-small {
  margin-left: 5px;
}
.margin-left-small {
  margin-left: 10px;
}
.margin-left-medium {
  margin-left: 25px;
}
.margin-left-large {
  margin-left: 50px;
}
.margin-left-extra-large {
  margin-left: 100px;
}
.margin-right-auto {
  margin-right: auto;
}
.margin-right-none {
  margin-right: 0;
}
.margin-right-extra-small {
  margin-right: 5px;
}
.margin-right-small {
  margin-right: 10px;
}
.margin-right-medium {
  margin-right: 25px;
}
.margin-right-large {
  margin-right: 50px;
}
.margin-right-extra-large {
  margin-right: 100px;
}
.margin-top-auto {
  margin-top: auto;
}
.margin-top-none {
  margin-top: 0;
}
.margin-top-extra-small {
  margin-top: 5px;
}
.margin-top-small {
  margin-top: 10px;
}
.margin-top-medium {
  margin-top: 25px;
}
.margin-top-large {
  margin-top: 50px;
}
.margin-top-extra-large {
  margin-top: 100px;
}
.margin-bottom-auto {
  margin-bottom: auto;
}
.margin-bottom-none {
  margin-bottom: 0;
}
.margin-bottom-extra-small {
  margin-bottom: 5px;
}
.margin-bottom-small {
  margin-bottom: 10px;
}
.margin-bottom-medium {
  margin-bottom: 25px;
}
.margin-bottom-large {
  margin-bottom: 50px;
}
.margin-bottom-extra-large {
  margin-bottom: 100px;
}
.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-x-none {
  margin-left: 0;
  margin-right: 0;
}
.margin-x-extra-small {
  margin-left: 5px;
  margin-right: 5px;
}
.margin-x-small {
  margin-left: 10px;
  margin-right: 10px;
}
.margin-x-medium {
  margin-left: 25px;
  margin-right: 25px;
}
.margin-x-large {
  margin-left: 50px;
  margin-right: 50px;
}
.margin-x-extra-large {
  margin-left: 100px;
  margin-right: 100px;
}
.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-y-none {
  margin-top: 0;
  margin-bottom: 0;
}
.margin-y-extra-small {
  margin-top: 5px;
  margin-bottom: 5px;
}
.margin-y-small {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin-y-medium {
  margin-top: 25px;
  margin-bottom: 25px;
}
.margin-y-large {
  margin-top: 50px;
  margin-bottom: 50px;
}
.margin-y-extra-large {
  margin-top: 100px;
  margin-bottom: 100px;
}
.padding-auto {
  padding: auto;
}
.padding-none {
  padding: 0;
}
.padding-extra-small {
  padding: 5px;
}
.padding-small {
  padding: 10px;
}
.padding-medium {
  padding: 25px;
}
.padding-large {
  padding: 50px;
}
.padding-extra-large {
  padding: 100px;
}
.padding-left-auto {
  padding-left: auto;
}
.padding-left-none {
  padding-left: 0;
}
.padding-left-extra-small {
  padding-left: 5px;
}
.padding-left-small {
  padding-left: 10px;
}
.padding-left-medium {
  padding-left: 25px;
}
.padding-left-large {
  padding-left: 50px;
}
.padding-left-extra-large {
  padding-left: 100px;
}
.padding-right-auto {
  padding-right: auto;
}
.padding-right-none {
  padding-right: 0;
}
.padding-right-extra-small {
  padding-right: 5px;
}
.padding-right-small {
  padding-right: 10px;
}
.padding-right-medium {
  padding-right: 25px;
}
.padding-right-large {
  padding-right: 50px;
}
.padding-right-extra-large {
  padding-right: 100px;
}
.padding-top-auto {
  padding-top: auto;
}
.padding-top-none {
  padding-top: 0;
}
.padding-top-extra-small {
  padding-top: 5px;
}
.padding-top-small {
  padding-top: 10px;
}
.padding-top-medium {
  padding-top: 25px;
}
.padding-top-large {
  padding-top: 50px;
}
.padding-top-extra-large {
  padding-top: 100px;
}
.padding-bottom-auto {
  padding-bottom: auto;
}
.padding-bottom-none {
  padding-bottom: 0;
}
.padding-bottom-extra-small {
  padding-bottom: 5px;
}
.padding-bottom-small {
  padding-bottom: 10px;
}
.padding-bottom-medium {
  padding-bottom: 25px;
}
.padding-bottom-large {
  padding-bottom: 50px;
}
.padding-bottom-extra-large {
  padding-bottom: 100px;
}
.padding-x-auto {
  padding-left: auto;
  padding-right: auto;
}
.padding-x-none {
  padding-left: 0;
  padding-right: 0;
}
.padding-x-extra-small {
  padding-left: 5px;
  padding-right: 5px;
}
.padding-x-small {
  padding-left: 10px;
  padding-right: 10px;
}
.padding-x-medium {
  padding-left: 25px;
  padding-right: 25px;
}
.padding-x-large {
  padding-left: 50px;
  padding-right: 50px;
}
.padding-x-extra-large {
  padding-left: 100px;
  padding-right: 100px;
}
.padding-y-auto {
  padding-top: auto;
  padding-bottom: auto;
}
.padding-y-none {
  padding-top: 0;
  padding-bottom: 0;
}
.padding-y-extra-small {
  padding-top: 5px;
  padding-bottom: 5px;
}
.padding-y-small {
  padding-top: 10px;
  padding-bottom: 10px;
}
.padding-y-medium {
  padding-top: 25px;
  padding-bottom: 25px;
}
.padding-y-large {
  padding-top: 50px;
  padding-bottom: 50px;
}
.padding-y-extra-large {
  padding-top: 100px;
  padding-bottom: 100px;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
@media only screen and (max-width: 400px) {
  .align-center-less-than-extra-small {
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  .align-center-less-than-small {
    text-align: center;
  }
}
@media only screen and (max-width: 800px) {
  .align-center-less-than-medium {
    text-align: center;
  }
}
@media only screen and (max-width: 960px) {
  .align-center-less-than-large {
    text-align: center;
  }
}
@media only screen and (max-width: 1200px) {
  .align-center-less-than-extra-large {
    text-align: center;
  }
}
.hide {
  display: none;
}
@media only screen and (max-width: 400px) {
  .hide-less-than-extra-small {
    display: none;
  }
}
@media only screen and (min-width: 401px) {
  .hide-more-than-extra-small {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .hide-less-than-small {
    display: none;
  }
}
@media only screen and (min-width: 601px) {
  .hide-more-than-small {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .hide-less-than-medium {
    display: none;
  }
}
@media only screen and (min-width: 801px) {
  .hide-more-than-medium {
    display: none;
  }
}
@media only screen and (max-width: 960px) {
  .hide-less-than-large {
    display: none;
  }
}
@media only screen and (min-width: 961px) {
  .hide-more-than-large {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .hide-less-than-extra-large {
    display: none;
  }
}
@media only screen and (min-width: 1201px) {
  .hide-more-than-extra-large {
    display: none;
  }
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cursor-pointer {
  cursor: pointer;
}
.absolute-fill {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.transition-color {
  transition: color 0.2s;
}
.transition-background-color {
  transition: background-color 0.2s;
}
.weight-light {
  font-weight: 300;
}
.weight-regular {
  font-weight: 400;
}
.weight-bold {
  font-weight: 600;
}
.font-size-extra-small {
  font-size: 12px;
}
.font-size-small {
  font-size: 16px;
}
.font-size-medium {
  font-size: 20px;
}
.font-size-large {
  font-size: 30px;
}
.font-size-extra-large {
  font-size: 40px;
}
.italic {
  font-style: italic;
}
.border-bottom {
  border-bottom: 1px solid #ECEBF0;
}
.form-section-navigation {
  width: 250px;
  background-color: #999999;
  overflow: hidden;
  border-radius: 5px;
  color: white;
}
.form-section-navigation__item {
  position: relative;
  width: 100%;
  font-size: 16px;
  padding: 10px 20px;
  text-align: center;
  transition: background-color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-section-navigation__item--complete {
  background-color: #303133;
}
.form-section-navigation__item--incomplete {
  background-color: #a94242;
}
.form-section-navigation__item--active {
  background-color: #6C8849;
}
.dashboard-section-card-row {
  display: flex;
  flex-wrap: wrap;
}
.dashboard-section-card-container {
  width: 33%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.dashboard-section-card {
  width: 100%;
  background-color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ECEBF0;
}
.dashboard-section-card__title {
  width: 100%;
  color: #2e1a47;
  margin-top: 0;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-section-card__icon {
  width: 140px;
  height: 140px;
  margin-bottom: 20px;
  background-size: 70px;
  background-repeat: no-repeat;
  background-position: center;
}
.dashboard-section-card__action {
  width: 100%;
  min-height: 24px;
  background-size: 24px;
  padding-left: 30px;
  margin-bottom: 5px;
  background-position: left top;
  background-image: url("/images/icons/tag-cross-red.svg");
  background-repeat: no-repeat;
  text-align: left;
  color: #999999;
}
.dashboard-section-card__action--done {
  background-image: url("/images/icons/tag-tick-green.svg");
}
.dashboard-section-card__button-container {
  width: 100%;
  margin-top: auto;
  padding-top: 10px;
}
@media only screen and (max-width: 1200px) {
  .dashboard-section-card-row {
    display: block;
  }
  .dashboard-section-card-container {
    width: 100%;
    max-width: 500px;
    margin: 10px auto;
  }
}
.dashboard-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  min-height: 100vh;
  margin-bottom: -100px;
}
.dashboard-layout__side-panel {
  width: 250px;
  overflow: auto;
}
.dashboard-layout__main {
  width: calc(100% - 250px);
  height: 100%;
}
.dashboard-layout__content {
  min-height: calc(100vh - 400px);
  padding-bottom: 100px;
}
@media only screen and (max-width: 800px) {
  .dashboard-layout__side-panel {
    display: none;
  }
  .dashboard-layout__main {
    width: 100%;
  }
}
.dashboard-side-panel {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.dashboard-side-panel__gap-fill {
  border-right: 1px solid #ECEBF0;
  flex: 1;
}
.dashboard-company-name-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  font-size: 26px;
  text-align: center;
  font-weight: bold;
  background-color: #6C8849;
  background-image: url("/images/koru-dim.svg");
  background-size: 160%;
  background-position: center;
  background-repeat: no-repeat;
}
.dashboard-company-name-box--tall {
  min-height: 200px;
}
.dashboard-location-box {
  padding: 20px;
  padding-left: 70px;
  background-color: #999999;
  color: white;
  background-image: url("/images/icons/location-marker-white.svg");
  background-position: 20px center;
  background-size: 40px;
  background-repeat: no-repeat;
}
.dashboard-help-box {
  padding: 40px 20px;
  text-align: center;
  background-color: #edecf2;
  color: #999999;
  cursor: pointer;
  transition: color 0.2s;
}
.dashboard-help-box--sent {
  color: #6C8849;
}
.dashboard-help-box__image {
  width: 80px;
}
.dashboard-help-box__label {
  text-decoration: underline;
}
.dashboard-action-progress {
  width: 100%;
  height: 200px;
}
.dashboard-action-progress__circle {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 0 50px;
  background-image: url("/images/dots-and-lines.svg");
  background-repeat: no-repeat;
}
.dashboard-action-progress__circle__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #6C8849;
}
.dashboard-section-results__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard-section-results__row__name {
  color: #2e1a47;
  width: 140px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-section-results__row__progress-bar {
  width: calc(100% - 190px);
  height: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}
.dashboard-section-results__row__progress-bar__progress {
  height: 10px;
  border-radius: 5px;
  animation: progress-bar-line-animation 1s ease-out forwards;
  transition: width 0.2s, background-color 0.2s;
}
.dashboard-section-results__row__percentage {
  width: 50px;
  font-weight: 600;
  text-align: right;
  transition: color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@keyframes progress-bar-line-animation {
  from {
    width: 0;
  }
}
.video-frame {
  width: 100%;
  height: 540px;
}
@media (max-width: 900px) {
  .video-frame {
    height: 500px;
  }
}
@media (max-width: 800px) {
  .video-frame {
    height: 400px;
  }
}
@media (max-width: 700px) {
  .video-frame {
    height: 350px;
  }
}
@media (max-width: 600px) {
  .video-frame {
    height: 300px;
  }
}
@media (max-width: 500px) {
  .video-frame {
    height: 250px;
  }
}
@media (max-width: 400px) {
  .video-frame {
    height: 200px;
  }
}
.header {
  background-color: #2e1a47;
  padding: 20px;
  background-image: url("/images/koru-white-dim.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  text-align: center;
}
.header__logo {
  max-height: 80px;
}
.ws-pw {
  white-space: pre-wrap;
}
.fs-italic {
  font-style: italic;
}
