h1 {
  font-size: 36px;
  color: #2E1A47;
  line-height: 45px;
  font-weight: 400;
}

h2 {
  font-size: 27px;
  color: #2E1A47;
  line-height: 36px;
  font-weight: 500;
}

h3 {
  font-size: 22.5px;
  color: #2E1A47;
  letter-spacing: 0;
  line-height: 29.7px;
  font-weight: 500;
}

h4 {
  font-size: 18px;
  color: #303133;
  letter-spacing: 0;
  line-height: 27px;
  font-weight: 700;
}

h5 {
  font-size: 15.3px;
  color: #2E1A47;
  letter-spacing: 0.5px;
  line-height: 19.8px;
  text-transform: uppercase;
  font-weight: 400;
}

.hero-heading {
  font-size: 48px;
  color: #2E1A47;
  letter-spacing: 1px;
  line-height: 61px;
  font-weight: 700;
}
