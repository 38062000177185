.section {
  width: 100%;
  padding: 50px 30px;
}

.section--padding-small {
  padding-top: 20px;
  padding-bottom: 20px;
}

.section__content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

each(@content-widths, .(@width, @name) {
  .section__content--@{name} {
    max-width: @width;
  }
});
