.horizontal-line {
  height: 1px;
  background-color: @colors[light-grey];
  margin: 0 auto;
}

.horizontal-line--width-extra-small {
  max-width: 300px;
}

.horizontal-line--thick {
  height: 4px;
}

.horizontal-line--primary {
  background-color: @colors[primary];
}
