.dashboard-action-progress {
  width: 100%;
  height: 200px;
}

.dashboard-action-progress__circle {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 0 50px;
  background-image: url("/images/dots-and-lines.svg");
  background-repeat: no-repeat;
}

.dashboard-action-progress__circle__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: @colors[primary];
}
