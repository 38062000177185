.screen {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100%;
}

.screen__content {
  position: relative;
}
