@dashboard-layout-overlay: 100px;

.dashboard-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  min-height: 100vh;
  margin-bottom: -@dashboard-layout-overlay;
}

.dashboard-layout__side-panel {
  width: 250px;
  overflow: auto;
}

.dashboard-layout__main {
  width: calc(100% - 250px);
  height: 100%;
}

.dashboard-layout__content {
  min-height: calc(100vh - 400px);
  padding-bottom: @dashboard-layout-overlay;
}

@media only screen and (max-width: @content-widths[medium]) {
  .dashboard-layout__side-panel {
    display: none;
  }

  .dashboard-layout__main {
    width: 100%;
  }
}
