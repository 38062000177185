.alert-banner {
  padding: 20px;
  padding-left: 80px;
  background-color: @colors[grey];
  color: white;
  background-image: url("/images/icons/info-circle-white.svg");
  background-size: 40px;
  background-position: 20px center;
  background-repeat: no-repeat;
  font-size: 16px;
  border-radius: 5px;
}
