.overlay-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(black, 0.2);
  z-index: @z-indexes[overlay];
}

.overlay-content-container {
  flex: 1 1 auto;
  max-height: 100%;
  max-width: 1000px;
  overflow: auto;
}
