.mobile-nav-panel {
  display: none;
  position: absolute;
  left: 0;
  top: 130px;
  width: 360px;
  max-width: 100%;
  background-color: @colors[purple];
  font-size: 16px;
  z-index: 5;
}

.mobile-nav-panel__row {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 26px;
  border-bottom: 1px solid white;
  transition: color @transition-duration;

  &:hover {
    color: white;
  }
}

.mobile-nav-panel__content {
  display: flex;
  flex-direction: column;
  padding: 26px;
}

.mobile-nav-panel__expansion {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-top: 10px;
}

.mobile-nav-panel__social-icon {
  width: 24px;
  padding-right: 6px;
  vertical-align: middle;
}

.mobile-nav-panel__link {
  box-sizing: border-box;
  width: 100%;
  margin: 5px 0;
  color: white;
  text-decoration: none;
  font-weight: 300;
}

.mobile-nav-panel__link--expansion {
  padding: 6px 0;
}

.mobile-nav-panel__link__text {
  display: inline-block;
  vertical-align: middle;
}

.mobile-nav-panel__expansion-button {
  width: 12px;
  margin-left: 10px;
}

@media only screen and (max-width: 960px) {
  .mobile-nav-panel {
    display: block;
    top: 116px;
  }
}

@media only screen and (max-width: 640px) {
  .mobile-nav-panel {
    display: block;
    top: 51px;
    width: 100%;
  }
}
