html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  background-color: @colors[background];
  color: @colors[text];
  font-family: @fonts[primary];
  -webkit-font-smoothing: antialiased;
  -mox-osx--font-smoothing: grayscale;
  font-size: 16px;
}
