.progress-bar {
  height: 2px;
  background-color: rgba(black, 0.1);
}

.progress-bar__progress {
  height: 2px;
  background-color: @colors[primary];
  transition: width 0.5s;
}
