.input-wrapper {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}

.input-wrapper__input {
  flex: 1;
  height: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
}

.input-wrapper__input:focus + .input-wrapper__icon {
  background-color: @colors[primary];
}

.input-wrapper__icon {
  width: 40px;
  height: 40px;
  background-color: @colors[grey];
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: background-color @transition-duration;
}

.input-wrapper__icon--arrow-icon {
  background-image: url('/images/icons/down-white.svg');
  background-position: center;
  background-size: 30%;
  background-repeat: no-repeat;
}

.input-wrapper__icon--calendar-icon {
  background-image: url('/images/icons/calendar-white.svg');
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}

.input-wrapper__icon--click-through {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
}

.input-wrapper__icon--split {
  display: flex;
  flex-direction: column;
}

.input-wrapper__icon__split {
  flex: 1;
  width: 100%;
  cursor: pointer;
  transition: background-color @transition-duration;

  &:hover {
    background-color: @colors[primary];
  }
}

.input-wrapper__icon__split + .input-wrapper__icon__split {
  border-top: 1px solid white;
}

.input-dim {
  .input-wrapper__icon__split + .input-wrapper__icon__split {
    border-top-color: rgba(black, 0.2);
  }
}

.input-wrapper__icon__split--up-arrow {
  background-image: url('/images/icons/down-white.svg');
  background-position: center;
  background-size: 30%;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

.input-wrapper__icon__split--down-arrow {
  background-image: url('/images/icons/down-white.svg');
  background-position: center;
  background-size: 30%;
  background-repeat: no-repeat;
}
